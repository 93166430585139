<template>
  <v-container class="py-12">
    <v-row justify="center">
      <v-col cols="12" class="text-center mb-8">
        <h2 class="text-h4 font-weight-bold text-primary">Kelebihan Kami</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(advantage, index) in advantages"
        :key="index"
        cols="12"
        md="4"
        class="text-center"
      >
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            v-bind="props"
            :elevation="isHovering ? 4 : 1"
            class="pa-4 h-100 d-flex flex-column transition-fast-in-fast-out"
            rounded="lg"
            flat
          >
            <div class="d-flex justify-center">
              <v-img
                :src="advantage.image"
                :srcset="getSrcSet(advantage.imageBase)"
                :alt="advantage.title"
                class="mb-4 mx-auto"
                max-width="120"
                max-height="120"
                contain
                eager
              />
            </div>

            <h3 class="mb-4 text-h5 font-weight-bold text-primary">
              {{ advantage.title }}
            </h3>

            <p class="text-grey-darken-1 text-body-1 flex-grow-1">
              {{ advantage.description }}
            </p>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
// Reactive data structure for advantages
const advantages = [
  {
    title: "Inclusive Platform",
    description:
      "Sebuah konsep dimana setiap individu tanpa memandang latar belakang memiliki akses dan kesempatan yang sama untuk berpartisipasi dalam suatu ekosistem yang memiliki tujuan memperkuat kekuatan masyarakat melalui partisipasi aktif dan berkontribusi untuk perubahan positif.",
    image: "/assets/images/inclusive-platform.webp",
    imageBase: "/assets/images/inclusive-platform",
  },
  {
    title: "No Money Oriented",
    description:
      "Fokus pada tujuan untuk mendorong kebersamaan, solidaritas, dan kebermanfaatan untuk sesama tanpa didasarkan pada keuntungan pribadi maupun kelompok.",
    image: "/assets/images/no-money-oriented.webp",
    imageBase: "/assets/images/no-money-oriented",
  },
  {
    title: "Social to Professional",
    description:
      "Semangat kebersamaan dan kerja sama sosial menjadi pondasi yang kuat untuk membangun keterampilan profesional dari setiap individu demi mencapai tujuan bersama yang lebih terstruktur dan terorganisir.",
    image: "/assets/images/social-to-professional.webp",
    imageBase: "/assets/images/social-to-professional",
  },
];

// Function to generate srcset for responsive images
const getSrcSet = (basePath: string) => {
  return `
    ${basePath}-sm.webp 120w,
    ${basePath}.webp 240w
  `;
};
</script>

<style scoped>
.v-card {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(var(--v-theme-surface-variant), 0.1);
}

.v-card:hover {
  transform: translateY(-5px);
}

/* Enhance text readability */
p {
  line-height: 1.6;
}

/* Equal height cards regardless of content length */
.h-100 {
  height: 100%;
}
</style>
